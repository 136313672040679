import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Arrow from "../images/arrow.inline.svg";
import IconOne from "../images/signature.inline.svg";
import IconTwo from "../images/digital.inline.svg";
import IconThree from "../images/identi.inline.svg";
import IconFour from "../images/tsp.inline.svg";

import Loadable from "@loadable/component";
const Map = Loadable(() => import("../components/map"));

function IndexPage({ data, pageContext: { lang, currentPath } }) {
  return (
    <Layout>
      <SEO
        title="API Solutions"
        description="LeverID is a modular solution that can be effortlessly integrated by businesses of all sizes, as well as provide a frictionless experience for end-users."
        image="https://www.leverid.com/thumbnail-new.png"
      />

      <Container className="home-first fullwidth banner-section regularheader">
        <Row className="container">
          <Col>
            <h4>API SOLUTIONS</h4>
            <h1>Begin harnessing the power of LeverID</h1>
            <p>
              LeverID is a modular solution that can be effortlessly integrated
              by businesses of all sizes, as well as provide a frictionless
              experience for end-users.
            </p>
            <Link to="/contact">
              Get in touch
              <Arrow />
            </Link>
          </Col>
          <Col className="bannerimage">
            <Img fluid={data.apiheader.childImageSharp.fluid} />
          </Col>
        </Row>
      </Container>

      <Container className="fullwidth bluelines white-section">
        <Container>
          <Row>
            <h2>
              LeverID transforms the most complex and granular of compliance
              standards into a straightforward and seamless experience.
            </h2>
          </Row>
          <Row>
            <Col>
              <h4>Frictionless integrations</h4>
              <p>
                We’ve created APIs that have been built from the ground up in
                order to ensure that your very own developers can utilize and
                integrate them with relative ease.
              </p>
            </Col>
            <Col>
              <h4>Future-proofing made easy</h4>
              <p>
                With regulations being fickle and ever-in flux, it’s essential
                that our solution is too. When compliance changes are required,
                no action is needed on your end — we’ll ensure LeverID is up to
                snuff.
              </p>
            </Col>
            <Col>
              <h4>No hidden fees </h4>
              <p>
                Using LeverID ensures that you won’t be subject to any large
                up-front charges for additional infrastructure or HSMs.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="home-third  fullwidth bluelines custom-sub-title">
        <Container>
          <Row>
            <h2>Whatever you need, LeverID delivers</h2>
            <p>
              Whether you’re focused on digital signatures, document management,
              or anything else in the sphere of e-services, you can rest assured
              that our solution will do all the legwork for you.
            </p>
          </Row>
          <Row>
            <Col>
              <div className="element-icon">
                <IconOne />
              </div>
              <h4>Signature Collections</h4>
              <p>APIs enabling remote signature collection from 3rd parties.</p>
            </Col>
            <Col>
              <div className="element-icon">
                <IconTwo />
              </div>
              <h4>Digital Signing</h4>
              <p>
                APIs for swift and secure signing, timestamping, and validation
                of files and documents.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="element-icon">
                <IconThree />
              </div>
              <h4>Identification</h4>
              <p>
                APIs for confirming users’ identities through various approved
                authentication methods.
              </p>
            </Col>
            <Col>
              <div className="element-icon">
                <IconFour />
              </div>
              <h4>TSP Monitoring</h4>
              <p>
                Reliable insights and overviews of TSPs (Trust Service
                Providers) and eIDs.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="fullwidth api-world">
        <Container>
          <Row>
            <Col>
              <h3>International services made easy</h3>
              <p>
                Despite the countless eID formats and systems being used
                throughout Europe, LeverID supports the majority of them,
                meaning cross-border services are easier to integrate than ever.
              </p>
              <Link to="/contact" className="regular-button-yellow">
                Get in touch
                <Arrow />
              </Link>
            </Col>
          </Row>
          <Map />
        </Container>
      </Container>
      <Container className="fullwidth home-fift centered-text">
        <Container>
          <Row style={{ display: "none" }}>
            <Col>
              <h3>Easy-to-Use for Everyone</h3>
              <p>
                It’s easy for people of all demographics and social groups to
                use LeverID to{" "}
                <strong>
                  authenticate their identity, sign documents and confirm
                  transactions.
                </strong>
              </p>
            </Col>
            <Col>
              <Img fluid={data.goverthree.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Img fluid={data.govertwo.childImageSharp.fluid} />
            </Col>
            <Col>
              <h3>
                LeverID is fully compliant with high-tier security standards
              </h3>
              <p>
                With security at the heart of what we do at LeverID, the
                standards we adhere to when designing and developing our
                solutions are second to none.
              </p>
            </Col>
          </Row>
          <Row style={{ display: "none" }}>
            <Col>
              <h3>Based on Patent-Pending Technology</h3>
              <p>
                LeverID is based on patent-pending technology, thereby forging
                the path for{" "}
                <strong>
                  new industry standards, as well as yielding sustainability.
                </strong>
              </p>
            </Col>
            <Col>
              <Img fluid={data.goverone.childImageSharp.fluid} />
            </Col>
          </Row>
        </Container>
      </Container>

      <div id="prefooter">
        <Container>
          <Row>
            <Col>
              <Img fluid={data.rihofull.childImageSharp.fluid} />
            </Col>
            <Col>
              <h3>
                Explore how your business
                <br /> can reap the benefits of LeverID
              </h3>
              <p>
                To learn more about how LeverID could serve your needs, please
                reach out using the button below.
              </p>
              <Link to="/contact">
                Contact us <Arrow />
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rihofull: file(relativePath: { eq: "riho_full.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    apiheader: file(relativePath: { eq: "apiheaders.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    goverone: file(relativePath: { eq: "goverone.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    govertwo: file(relativePath: { eq: "api.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    goverthree: file(relativePath: { eq: "goverthree.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export default IndexPage;
