import { Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import LogoMain from "../images/logo.inline.svg";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./header";
import LogoLevercode from "../images/levlogo.inline.svg";
import "./layout.css";
import facebook from "../images/facebook.png";
import linkedin from "../images/linkedin.png";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <div id="footer">
        <Container>
          <Row className="first-footer">
            <Col>
              <LogoMain />
              <LogoLevercode />
              <p>
                LeverID is a single identity and signature platform for
                utilization by Government and Business verticals.
                <br />
                <br />A fully customizable platform with post-quantum
                capability, universal login functionality, and robust security.
              </p>
            </Col>
            <Col>
              <h4>Navigation</h4>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about-lever-id">About LeverID</Link>
                </li>
                <li>
                  <Link to="/government-solutions">Government Solutions</Link>
                </li>
                <li>
                  <Link to="/corporate-solutions">Corporate Solutions</Link>
                </li>
                <li>
                  <Link to="/api-solutions">API Solutions</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </Col>
            <Col>
              <h4>Contact</h4>
              <ul>
                <li>
                  <a href="mailto:info@leverid.com">info@leverid.com</a>
                </li>
                <li>
                  <a href="tel:+372 65 65 600">+372 65 65 600</a>
                </li>
                <li className="addresslink">
                  <a
                    href="https://www.google.com/maps?q=Tammsaare+%C3%84rikeskus,+Tallinn+13283,+Estonia&rlz=1C5CHFA_enEE876EE876&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjJn8mkprLwAhUfAxAIHc3kBIYQ_AUoAXoECAEQAw"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    A. H. Tammsaare tee 47,
                    <br /> Tallinn 11316, Estonia
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="last-footer">
            <Col>
              <p>Copyright {new Date().getFullYear()}. Levercode Ltd.</p>
            </Col>
            <Col className="footer-social">
              <a
                href="https://www.facebook.com/levercode"
                target="_blank"
                className="social-icon"
                rel="noreferrer noopener nofollow"
              >
                <img src={facebook} alt="Facebook logo"/>
              </a>
              <a
                href="https://www.linkedin.com/company/levercode"
                target="_blank"
                rel="noreferrer noopener nofollow"
                className="social-icon xl:mr-12"
              >
                <img src={linkedin} alt="Linkedin logo" />
              </a>
            </Col>
            <Col>
              <ul>
                <li>
                  <Link to="/privacy_notice">Privacy Notice</Link>
                </li>
                <li>
                  <Link to="/terms_of_service">Terms of Service</Link>
                </li>
                <li>
                  <a href="https://levercode.com/" target="_blank" rel="noreferrer noopener nofollow">
                    Levercode.com
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
